import React, { useState, useEffect, useRef } from 'react';
import GalleryItem from '../../components/GalleryItem';
import '../../App.css';

const GalleryPrint = ({ gallery, userObj }) => {
  return (
    <div>
      <GalleryItem
        key={gallery.id}
        galleryObj={gallery}
        isOwner={gallery.creatorId === userObj.uid}
      />
    </div>
  );
};

export default GalleryPrint;
