/*
    �ڵ� �� no1. ���� �۵��ȴٸ� �ǵ��� ����. �̰� �� �������� ���� �� �ߴµ� ������ �۵� �� �Ǵϱ� �� �ǵ帮�°� ������.
*/

import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

export const Nav = styled.nav``;
export const Bars = styled(FaBars)``;
export const NavMenu = styled.div``;
export const NavLink = styled(Link)``;
export const NavBtn = styled.nav``;
export const NavBtnLink = styled(Link)``;
