import React, {useState} from "react";
import {Button} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "./Faculties.css";
import dayun from "../../images/dayun.jpg";
import jimin from "../../images/jimin.jpg";
// import sohyun from "../../images/sohyeon.jpeg";
import yujin from "../../images/yujin.jpg";
import junseo from "../../images/junseo.jpg";

import Eric from "../../images/Eric.jpeg";
import Byunghoon from "../../images/Byunghoon.jpg";
import SoHyeon from "../../images/SoHyeon.jpg";
import Chanjin from "../../images/Chanjin.jpeg";
import Jiwoo from "../../images/Jiwoo.jpeg";
import Yukyeong from "../../images/Yukyeong.jpeg";

import Header from "../../components/Header";

function Faculties() {
  {
    /* change in img, need linkedin links and role from everyone */
  }
  const [cards] = useState([
    {
      image: (
        <img
          className="img1"
          src={Eric}
          alt="Eric"
          style={{width: "220px", height: "330px"}}
        />
      ),
      title: "Eric Jeon",
      major: "Accounting",
      role: "President",
      linked_in: (
        <Button
          href="https://www.instagram.com/ericjsm_0915/?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          circular
          color="instagram"
          icon="instagram"
        />
      ),
    },
    {
      image: (
        <img
          className="img1"
          src={Byunghoon}
          alt="Byunghoon"
          style={{width: "220px", height: "330px"}}
        />
      ),
      title: "Byunghoon Yoo",
      major: "Information Technology & Systems",
      role: "Vice President / Head of Operations",
      linked_in: (
        <Button
          href="https://www.instagram.com/byunghoon._._/?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          circular
          color="instagram"
          icon="instagram"
        />
      ),
    },
    {
      image: (
        <img
          className="img1"
          src={SoHyeon}
          alt="SoHyeon"
          style={{width: "220px", height: "330px"}}
        />
      ),
      title: "So Hyeon Yoo",
      major: "Supply Chain",
      role: "Secretary / Head of Finance",
      linked_in: (
        <Button
          href="https://www.instagram.com/yoosocute._.b/?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          circular
          color="instagram"
          icon="instagram"
        />
      ),
    },
    {
      image: (
        <img
          className="img1"
          src={Chanjin}
          alt="Chanjin"
          style={{width: "220px", height: "330px"}}
        />
      ),
      title: "Chanjin Kim",
      major: "Finance",
      role: "Treasurer / Supplychain Management",
      linked_in: (
        <Button
          href="https://www.instagram.com/realmon4d/?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          circular
          color="instagram"
          icon="instagram"
        />
      ),
    },
    {
      image: (
        <img
          className="img1"
          src={Jiwoo}
          alt="Jiwoo"
          style={{width: "220px", height: "330px"}}
        />
      ),
      title: "Jiwoo Oh",
      major: "Accounting",
      role: "Treasurer / Head of Finance",
      linked_in: (
        <Button
          href="https://www.instagram.com/0o0_jw_/?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          circular
          color="instagram"
          icon="instagram"
        />
      ),
    },
    {
      image: (
        <img
          className="img1"
          src={Yukyeong}
          alt="Jiwoo"
          style={{width: "220px", height: "330px"}}
        />
      ),
      title: "Yukyeong Ko",
      major: "Computer Science",
      role: "Head of Planning",
      linked_in: (
        <Button
          href="https://www.instagram.com/ugxngko/?hl=en"
          target="_blank"
          rel="noreferrer noopener"
          circular
          color="instagram"
          icon="instagram"
        />
      ),
    },
  ]);
  return (
    <div>
      <section>
        <Header headerText={"Meet our Executive Officers"} />
        <div className="cards">
          {cards.map((card, i) => (
            <div key={i} className="card">
              <image className="img1">{card.image}</image>
              <h3>{card.title}</h3>
              <p className="job">{card.role}</p>
              <p className="major">{card.major}</p>
              <div>{card.linked_in}</div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Faculties;
