import React from 'react';

import GalleryItem from './GalleryItem';
import './GalleryList.css';

const GalleryList = (props) => {
	if (props.items.length === 0) {
		return (
			<div className='center'>
				<h2>No items found.</h2>
			</div>
		);
	}
	let gridedList = [];
	props.items.forEach((item, index) => {
		// 현재 항목을 결과 배열에 추가
		if (index % 3 === 0) {
			gridedList.push([item]);
		} else {
			// 이전 배열에 현재 항목을 추가
			gridedList[gridedList.length - 1].push(item);
		}
	});
	return (
		<ul>
			{gridedList.map((row) => (
				<div className='items-list'>
					{row.map((item) => (
						<GalleryItem
							key={item.id}
							galleryObj={item}
							isOwner={item.creatorId === props.userObj.uid}
						/>
					))}
				</div>
			))}
		</ul>
	);
};

export default GalleryList;
