import React from 'react';
import { faFacebook, faInstagram, faLinkedin, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RiKakaoTalkFill } from 'react-icons/ri';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';


const Footer = (props) => {
    return (
        <div id="footer">
            <div className='forCol'>
                <div className="col1">
                    <img 
                        src={require('../images/logo(2).png')}
                        style={{ width: '150px', height: 'auto' }}
                    />
                    <div className='kisaName'>
                    Korean<br/>International<br/>Students<br/>Association
                    </div>
                </div>
                <div className="col2">
                    <div className="ContactUs"> Contact Us </div>
                    Email: utdallas.kisa@gmail.com
                    <br /> KakaoTalk: https://open.kakao.com/o/gLYn6HHd
                    <br /> 800 W Campbell Rd, Richardson, TX 75080
                    <br /> &copy;{new Date().getFullYear()} Korean International Students Association
                </div>
                <div>
                    <hr />
                </div>
            </div>
                
            
            <div className="singleCol  social-media-icons-white
                    d-flex  justify-content-evenly">
                        <a href="https://www.facebook.com/UTDKISA">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="https://www.instagram.com/utd_kisa">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://open.kakao.com/o/gLYn6HHd">
                            <RiKakaoTalkFill />
                        </a>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfc0zO8Wf_aH7Qv62ImUYkgPWFief30mhM-All3QR6TToiGcA/viewform">
                            <FontAwesomeIcon icon={faUserPlus} />
                        </a>
                    </div>
        </div>
    )
}

export default Footer;