import {useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import {
  faDiscord,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RiKakaoTalkFill} from "react-icons/ri";
import "./Contact.css";

const Contact = () => {
  const form = useRef();
  const [showModal, setShowModal] = useState(false);
  const openModalHandler = () => setShowModal(true);
  const closeModalHandler = () => setShowModal(false);
  const [isSended, setIsSended] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const res = await emailjs.sendForm(
        "service_pq7t32c",
        "template_uycwwgj",
        form.current,
        "oaYYAkXHWn7P8yPJn",
      );
      setIsSended(true);
    } catch (err) {
      console.log(err);
      setIsSended(false);
    }
    setShowModal(true);
    e.target.reset();
  };

  return (
    <>
      <Modal
        show={showModal}
        header={isSended ? "Email sent!" : "Error"}
        onCancel={closeModalHandler}
        contentClass="confirm"
        footerClass="place-item__modal-actions"
        footer={<Button onClick={closeModalHandler}>Okay</Button>}>
        {isSended
          ? "The email has been successfully sent."
          : "Something went wrong. Please try again later."}
      </Modal>
      <section>
        <div className="DScontainer">
          <div className="DStopside">
            <div className="DSlogo1">
              <img
                className="DSlogoOne"
                src={require("../../images/logo.jpg")}
                alt="Logo"
              />
            </div>

            <div className="DSOtherContact">
              <div className="ContactInformationHeading">
                Contact Information
              </div>
              <ul className="ContactInformationContents">
                <li>
                  {"Facebook : "}
                  <a href="https://www.facebook.com/UTDKISA">UTDKISA</a>
                </li>
                <li>
                  {"Instagram : "}
                  <a href="https://www.instagram.com/utd_kisa">@utd_kisa</a>
                </li>
                <li>
                  {"KakaoTalk : "}
                  <a href="https://open.kakao.com/o/gLYn6HHd">
                    KakaoTalk Open Chat
                  </a>
                </li>
                <li>
                  {"Location : "}
                  <a href="https://www.google.com/maps/place/University+of+Texas+at+Dallas/@32.9856974,-96.7546248,17z/data=!4m10!1m2!2m1!1sutd!3m6!1s0x864c21ff895e4aa5:0xd9098b32e9aa1331!8m2!3d32.9856974!4d-96.7502474!15sCgN1dGSSARFwdWJsaWNfdW5pdmVyc2l0eeABAA!16zL20vMDJtdDJu">
                    800 W Campbell Rd, Richardson, TX 75080
                  </a>
                </li>
              </ul>

              <div className="DSsocialMedia social-media-icons-white d-flex justify-content-evenly">
                <a href="https://www.facebook.com/UTDKISA">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/utd_kisa">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://open.kakao.com/o/gLYn6HHd">
                  <RiKakaoTalkFill />
                </a>
                <a href="https://discord.com/invite/6GNPSbm5Sw">
                  <FontAwesomeIcon icon={faDiscord} />
                </a>
              </div>
            </div>
          </div>

          <div className="ContactContent">
            <form ref={form} onSubmit={sendEmail}>
              <div className="DSslogan">
                If you have any questions or would like to contact us, please
                fill out the form below. Our KISA executives are ready and
                waiting to hear from you.
              </div>
              <div className="DSEmailInput">
                <div className="DSkisaform">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type Name"
                      name="from_name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Type Email Address"
                      name="user_email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type Subject"
                    name="subject"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Contents</label>
                  <textarea
                    name="message"
                    placeholder="Enter Your Message Here!"
                    required></textarea>
                  <button type="submit" className="DSbutton">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>

          <i className="bi bi-chat-left-text-fill"></i>
        </div>
      </section>
    </>
  );
};

export default Contact;
