import React, {useState, useEffect} from "react";
import Body from "./Body";
import {NavLink} from "../Navbar/NavBarElements";
import Calendar1 from "./Calendar1";
import Bottom from "./Bottom";
import SponsorList from "./../../components/SponsorList";
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import {dbService, storageService} from "../../fbase";
import "./Main.css";

function Main() {
  const images = [
    "https://i.imgur.com/10nvyIU.jpg",
    "https://i.imgur.com/9793W9X.jpg",
    "https://i.imgur.com/urrFZOj.jpg",
    "https://i.imgur.com/npcnUqV.jpg",
  ];
  const [sponsors, setSponsors] = useState([]);
  useEffect(() => {
    const q = query(collection(dbService, "sponsors"));
    onSnapshot(q, (snapshot) => {
      const sponsorsArr = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSponsors(sponsorsArr);
    });
  }, []);
  console.log(sponsors);
  return (
    <div id="main" className="main">
      <div className="header-heading">
        <Body images={images} className="slide" />;
        <section className="features" id="features">
          <h1 className="heading">
            KISA <span>Features</span>
          </h1>
          <div className="box-container">
            <div className="box">
              <img
                src={require("../../images/About Us.jpg")}
                height={200}
                width={200}
              />
              <h3>About us</h3>
              <p> Discover the essence of who we are and what we stand for!</p>
              <NavLink to="/AboutUs" className="btn">
                read more
              </NavLink>
            </div>
            <div className="box">
              <img
                src={require("../../images/Faculties.jpg")}
                height={200}
                width={200}
              />
              <h3>Faculties</h3>
              <p>
                Introducing the executive board of the Korean student
                association!
              </p>
              <NavLink to="/Faculties" className="btn">
                read more
              </NavLink>
            </div>
            <div className="box">
              <img
                src={require("../../images/Event.jpg")}
                height={200}
                width={200}
              />
              <h3>Event</h3>
              <p> Exciting upcoming events for everyone to look forward to!</p>
              <NavLink to="/AboutUs" className="btn">
                read more
              </NavLink>
            </div>
            <div className="box">
              <img
                src={require("../../images/Source.jpg")}
                height={200}
                width={200}
              />
              <h3>Gallery</h3>
              <p> Explore our captivating gallery of memorable moments!</p>
              <NavLink to="/AboutUs" className="btn">
                read more
              </NavLink>
            </div>
          </div>
        </section>
        <h1 className="heading">
          KISA <span>Calendar</span>
        </h1>
        <Calendar1 className="calendar" />
      </div>

      <h1 className="heading">
        KISA <span>Instagram</span>
      </h1>
      <Bottom />
      <h1 className="heading">
        KISA <span>Sponsor</span>
      </h1>
      <div>{<SponsorList items={sponsors} />}</div>
    </div>
  );
}

export default Main;
