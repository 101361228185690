import React from "react";
import "./GalleryList.css";
import "./SponsorList.css";
const SponsorList = ({items}) => {
  if (items.length === 0) {
    return (
      <div className="center">
        <h2>We are waiting for our new sponsor!</h2>
      </div>
    );
  }
  let gridedList = [];
  const sponsorClassName = items.length < 3 ? "centered-list" : "items-list";
  items.forEach((item, index) => {
    // 현재 항목을 결과 배열에 추가
    if (index % 3 === 0) {
      gridedList.push([item]);
    } else {
      // 이전 배열에 현재 항목을 추가
      gridedList[gridedList.length - 1].push(item);
    }
  });
  return (
    <ul>
      {gridedList.map((row) => (
        <div className={sponsorClassName}>
          {row.map((item, key) => (
            // sponsor item
            <div className="sponsor-item">
              <img
                key={key}
                src={item.imageUrl}
                alt={item.name}
                height="300px"
                width="300px"
              />
              <a href={item.imageUrl} />
            </div>
          ))}
        </div>
      ))}
    </ul>
  );
};
export default SponsorList;
