import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

export function Bottom() {
  return (
    <>
      <div id="instagram">
                <InstagramEmbed url="https://www.instagram.com/p/ClhEPUlvXx0/?utm_source=ig_web_button_share_sheet" 
                    captioned className="post"/>
                <InstagramEmbed url="https://www.instagram.com/p/ClcqX6fsIV9/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==" 
                    captioned className="post"/>
                <InstagramEmbed url="https://www.instagram.com/p/ClCb4R_vOuf/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==" 
                    captioned className="post"/>
            </div> 

      
    </>
  );
}

export default Bottom;
