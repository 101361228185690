import React, { useState } from 'react';
import { NavLink, NavBtn, NavBtnLink } from './NavBarElements';
import SideDrawer from './SideDrawer';
import BackDrop from '../../components/Backdrop';
import NavLinks from './NavLinks';
import MainHeader from './MainHeader';
import './MainNavigation.css';
import { getAuth } from "firebase/auth";


const MainNavigation = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  
  const auth = getAuth();
  const user = auth.currentUser;
  
  if (user !== null) {
    console.log(user.photoURL);
  }

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };
  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };
  return (
    <>
      {drawerIsOpen && <BackDrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className='main-navigation__drawer-nav'>
          <NavLinks />
        </nav>
      </SideDrawer>

      <MainHeader>
        <button
          className='main-navigation__menu-btn'
          onClick={openDrawerHandler}>
          <span />
          <span />
          <span />
        </button>
        {/* <NavLink to='/Main' className='logoMenu1'></NavLink> */}
        {/* <div className='navbar'> */}
        <NavLink to='/' className='logoMenu'>
          <img src={require('../../images/logo.png')} className='logo' />
        </NavLink>
        <nav className='main-navigation__header-nav'>
          <NavLinks />
        </nav>

        <NavBtn className='button auth'>
          {props.isLoggedIn ? (
            <NavBtnLink to='/profile' className='bLink'>
              <img src={user.photoURL} className='pImage'/>
            </NavBtnLink>
          ) : (
            <NavBtnLink to='/signIn' className='bLink2'>
              Sign In
            </NavBtnLink>
          )}
        </NavBtn>
        {/* </div> */}
      </MainHeader>
    </>
  );
};

export default MainNavigation;
