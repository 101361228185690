import React from 'react';

import './Other.css';

function Other() {
    return (
        
        <div class="link">
            <a href="https://drive.google.com/drive/folders/1y-OhqeA5lZ6hMRXixwyVRw8AxvcmfKSv?usp=sharing"
                target="_blank"> Click For More Pictures </a>
            
            <p>Access For Members Only</p>
            </div>
    )
}

export default Other;