import React from 'react';
import { NavLink, NavBtn, NavBtnLink } from './NavBarElements';
import './NavLinks.css';

const NavLinks = (props) => {
  return (
    <ul className='Menu'>
      <li className='dropdown'>
        <div className='dropbtn'>
          About Us
          <div className='dropdown-menu'>
            <NavLink to='/AboutUs'>About KISA</NavLink>
            <NavLink to='/Faculties'>Faculties</NavLink>
          </div>
        </div>
      </li>
      |
      <li className='dropdown'>
        <div className='dropbtn'>
          Event
          <div className='dropdown-menu'>
            <NavLink to='/WriteMainPast'>Past Event</NavLink>
            <NavLink to='/WriteMainUpcome'>Upcoming Event</NavLink>
          </div>
        </div>
      </li>
      |
      <li>
        <div className='navlink-btn'>
          <NavLink to='/WriteMain' className='Link'>
            Gallery
          </NavLink>
        </div>
      </li>
      |
      <li>
        <div className='navlink-btn'>
          <NavLink to='/Contact' className='Link'>
            Contact
          </NavLink>
        </div>
      </li>
    </ul>
  );
};
export default NavLinks;
