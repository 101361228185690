import React from 'react';
import './Header.css';

const Header = ({ headerText }) => {
	return (
		<header className='all'>
			<h1 id='header'>
				{headerText}
				<hr />
			</h1>
		</header>
	);
};

export default Header;
