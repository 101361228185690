export const data = [
  {
    text: 'Established in 2019',
    date: '2019',
    category: {
      tag: 'UTD KISA',
      color: '#018f69',
    },
  },

  {
    text: 'Return to in-person',
    date: 'August 2021',
    category: {
      tag: 'UTD KISA',
      color: 'orange',
    },
  },

  {
    text: 'Hit 100 members',
    date: 'September 2022',
    category: {
      tag: 'UTD KISA',
      color: 'red',
    },
  },
];
