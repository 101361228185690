import React, { useState } from 'react';
import { dbService, storageService, db } from '../fbase';
import { doc, deleteDoc, setDoc, getFirestore } from 'firebase/firestore';
import { deleteObject, ref } from '@firebase/storage';
import Modal from '../components/Modal';
import './GalleryItem.css';

const GalleryItem = ({ galleryObj, isOwner }) => {
	const [showContent, setShowContent] = useState(false);
	const [like, setLike] = useState(false);

	const closeContentHandler = () => setShowContent(false);

	const GalleryTextRef = doc(dbService, 'gallery', `${galleryObj.id}`);
	const urlRef = ref(storageService, galleryObj.attachmentURL);
	const date = new Date(galleryObj.createdAt).toISOString().slice(0, 10);
	const likesNum = galleryObj.likes;

	const onLikeClicked = async () => {
		let likes = galleryObj.likes;

		if (!like) {
			likes += 1;
		} else {
			likes -= 1;
		}
		try {
			await setDoc(doc(db, 'gallery', galleryObj.id), {
				...galleryObj,
				likes: likes,
			});
		} catch (err) {}
		setLike((s) => !s);
	};

	const onShareClicked = () => {
		window.navigator.clipboard.writeText(window.location.href);
	};

	const onDeleteClick = async () => {
		const ok = window.confirm('Are you sure to delete?');
		console.log(ok);
		if (ok) {
			await deleteDoc(GalleryTextRef);
			await deleteObject(urlRef);
		}
	};
	const onViewContents = () => {
		setShowContent(true);
	};
	return (
		<>
			<Modal
				show={showContent}
				onCancel={closeContentHandler}
				contentClass='gallery'>
				<div>
					<h2>{galleryObj.title}</h2>
					<img src={galleryObj.attachmentUrl} />
					<div className='buttons'>
						<div onClick={onLikeClicked}>
							<button>{like ? '❤️' : '🤍'}</button>
							<span>like</span>
						</div>
						<div onClick={onShareClicked}>
							<button>🔗</button>
							<span>share</span>
						</div>
					</div>
					<div>
						<span className='modal__creator'>{galleryObj.creatorName} </span>
						<span>{galleryObj.description}</span>
					</div>
					<p>
						{galleryObj.likes + (galleryObj.likes > 1 ? ' likes' : ' like')}
					</p>
					<p className='modal__date'>{date}</p>
				</div>
			</Modal>
			<li class='parent1'>
				<div class='box1'>
					<div class='element'>
						<div class='img-gallery'>
							{galleryObj.attachmentUrl && (
								<img src={galleryObj.attachmentUrl} onClick={onViewContents} />
							)}
							{isOwner && (
								<>
									<button onClick={onDeleteClick}>Delete</button>
								</>
							)}
						</div>
					</div>
				</div>
			</li>
		</>
	);
};

export default GalleryItem;
